var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "mt-0"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "pt-0",
    attrs: {
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "d-flex text-center align-items-center top-div"
  }, [_c('router-link', {
    attrs: {
      "button": "",
      "to": "/my-order"
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "arrow-left-short"
    }
  })], 1), _c('h5', [_vm._v(" Order "), _c('span', [_vm._v("#" + _vm._s(_vm.orderDetailsData ? _vm.orderDetailsData.orderId : ""))])]), _c('div', {
    staticClass: "order-status status-type-div ml-3"
  }, [_c('p', [_vm._v(_vm._s(_vm.orderDetailsData ? _vm.orderDetailsData.orderStatus : ""))])]), _c('div', {
    staticClass: "order-type status-type-div ml-3"
  }, [_c('p', [_vm._v(_vm._s(_vm.orderDetailsData ? _vm.orderDetailsData.type : ""))])])], 1), _c('div', {
    staticClass: "d-flex text-center align-items-center order-date-div"
  }, [_c('b-icon', {
    attrs: {
      "icon": "calendar3"
    }
  }), _c('p', {
    staticClass: "ml-2"
  }, [_vm._v(_vm._s(_vm.createdDate))])], 1)])])], 1), _c('b-row', {
    staticClass: "mt-0"
  }, [_c('b-col', {
    attrs: {
      "md": "8"
    }
  }, [_vm.orderDetailsData.modeOfSale !== 'IN-STORE' ? _c('div', {
    staticClass: "ordered-item-div mb-3"
  }, [_c('h5', [_vm._v("Ordered Item")]), _vm._l(_vm.orderDetailsData.items, function (item, index) {
    return _c('ul', {
      key: index,
      staticClass: "list-unstyled mb-0"
    }, [_vm.$options.components['items-order-details-item'] ? [_c('items-order-details-item', {
      attrs: {
        "itemData": item
      }
    })] : _vm._e()], 2);
  })], 2) : _vm._e(), _c('div', {
    staticClass: "content-div"
  }, [_c('h5', [_vm._v("Seller Details")]), _c('div', {
    staticClass: "d-flex justify-content-between seller-name mt-3"
  }, [_c('div', [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('router-link', {
    attrs: {
      "to": {
        path: "/shop-details/".concat(_vm.orderDetailsData.provider._id, "/").concat(_vm.providerName)
      }
    }
  }, [_c('b-img', {
    staticClass: "seller-img",
    attrs: {
      "src": _vm.sellerInfo && _vm.sellerInfo.organization && _vm.sellerInfo.organization.logo ? _vm.sellerInfo.organization.logo.url : '',
      "alt": ""
    }
  })], 1), _c('router-link', {
    attrs: {
      "to": {
        path: "/shop-details/".concat(_vm.orderDetailsData.provider._id, "/").concat(_vm.providerName)
      }
    }
  }, [_c('p', {
    staticClass: "ml-2"
  }, [_vm._v(_vm._s(_vm.providerName))])])], 1), _c('div', {
    staticClass: "d-flex mt-3"
  }, [_c('b-icon', {
    staticClass: "ml-1 mr-1",
    attrs: {
      "icon": "geo-alt-fill"
    }
  }), _c('div', {
    staticClass: "ml-3"
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.sellerInfo && _vm.sellerInfo.office && _vm.sellerInfo.office.address ? _vm.sellerInfo.office.address.street1 : "") + " " + _vm._s(_vm.sellerInfo && _vm.sellerInfo.office && _vm.sellerInfo.office.address ? _vm.sellerInfo.office.address.city : "") + " ")]), _c('p', [_vm._v(" " + _vm._s(_vm.sellerInfo && _vm.sellerInfo.office && _vm.sellerInfo.office.address ? _vm.sellerInfo.office.address.state : "") + "- " + _vm._s(_vm.sellerInfo && _vm.sellerInfo.office && _vm.sellerInfo.office.address ? _vm.sellerInfo.office.address.zip : "") + " ")])])], 1)]), _c('div', [_c('div', {
    staticClass: "d-flex align-items-center pt-1"
  }, [_c('b-icon', {
    attrs: {
      "icon": "envelope-fill"
    }
  }), _c('p', {
    staticClass: "ml-2"
  }, [_vm._v(_vm._s(_vm.sellerMail))])], 1), _c('div', {
    staticClass: "d-flex align-items-center mt-3 pt-1"
  }, [_c('b-icon', {
    attrs: {
      "icon": "telephone-forward-fill"
    }
  }), _c('p', {
    staticClass: "ml-2"
  }, [_vm._v(_vm._s(_vm.sellerPhone))])], 1)])])]), _c('div', {
    staticClass: "mt-3 content-div"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between payment-box"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('h5', [_vm._v("Payment Summary")]), _vm.orderDetailsData.paymentStatus ? _c('div', {
    staticClass: "status-type-div payment-status ml-4"
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.paymentStatus) + " ")])]) : _vm._e(), _c('div', {
    staticClass: "status-type-div payment-type ml-4"
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.orderDetailsData ? _vm.orderDetailsData.paymentType : "") + " ")])])]), (_vm.orderDetailsData && _vm.orderDetailsData.paymentDetails && _vm.orderDetailsData.paymentDetails.transactionId ? true : false) ? _c('p', {
    staticClass: "payment-ref"
  }, [_vm._v(" Payment Ref: #" + _vm._s(_vm.orderDetailsData && _vm.orderDetailsData.paymentDetails ? _vm.orderDetailsData.paymentDetails.transactionId : "") + " ")]) : _vm._e()]), _vm._l(_vm.orderDetailsData.items, function (item) {
    return _c('div', {
      key: item._id
    }, [_vm.orderDetailsData.modeOfSale !== 'IN-STORE' ? _c('div', {
      staticClass: "d-flex justify-content-between mt-3"
    }, [_c('p', [_vm._v("Subtotal")]), _c('p', [_vm._v("₹ " + _vm._s(item.price))])]) : _vm._e(), item.delivery ? _c('div', {
      staticClass: "d-flex justify-content-between"
    }, [_c('p', [_vm._v("Shipping Cost")]), _c('p', [_vm._v("₹ " + _vm._s(item.delivery.charge))])]) : _vm._e(), item.taxes.length >= 1 ? _c('div', [_vm._l(item.taxes, function (tax) {
      return _c('div', {
        key: tax.type,
        staticClass: "d-flex justify-content-between"
      }, [_c('p', [_vm._v(_vm._s(tax.type) + " @ " + _vm._s(tax.percent) + "%")]), _c('p', [_vm._v("₹ " + _vm._s(tax.taxAmount.toFixed(2)))])]);
    }), _c('div', {
      staticClass: "d-flex justify-content-between"
    }, [_c('p', [_vm._v("Total Tax Amount")]), _c('p', [_vm._v("₹ " + _vm._s(item.totalTaxes))])])], 2) : _vm._e()]);
  }), _c('hr', {
    staticClass: "mt-2"
  }), _c('div', {
    staticClass: "d-flex justify-content-between total-bill"
  }, [_c('h5', [_vm._v("Total")]), _c('h5', [_vm._v(_vm._s(_vm.orderDetailsData ? _vm.orderDetailsData.orderAmount : ""))])])], 2), _c('div', {
    staticClass: "mt-3 content-div"
  }, [_c('h5', [_vm._v("Order Activity")]), _vm.orderDetailsData.orderStatus !== 'Cancelled' ? _c('div', {
    staticClass: "steps d-flex flex-column justify-content-between mt-3"
  }, [_c('div', {
    class: "step d-flex mb-5 ".concat(_vm.orderStatus(_vm.orderDetailsData.orderStatus) >= 0 ? 'completed' : '')
  }, [_c('div', {
    staticClass: "round"
  }), _c('div', {
    staticClass: "ml-4"
  }, [_c('h6', [_vm._v("Order Created")])])]), _c('div', {
    class: "step d-flex mb-5 ".concat(_vm.orderStatus(_vm.orderDetailsData.orderStatus) >= 1 ? 'completed' : '')
  }, [_c('div', {
    staticClass: "round"
  }), _c('div', {
    staticClass: "ml-4"
  }, [_c('h6', [_vm._v("Order Processing")])])]), _c('div', {
    class: "step d-flex mb-5 ".concat(_vm.orderStatus(_vm.orderDetailsData.orderStatus) >= 2 ? 'completed' : '')
  }, [_c('div', {
    staticClass: "round"
  }), _c('div', {
    staticClass: "ml-4"
  }, [_c('h6', [_vm._v("Shipped")])])]), _c('div', {
    class: "step d-flex mb-5 ".concat(_vm.orderStatus(_vm.orderDetailsData.orderStatus) >= 3 ? 'completed' : '')
  }, [_c('div', {
    staticClass: "round"
  }), _c('div', {
    staticClass: "ml-4"
  }, [_c('h6', [_vm._v("Order Delivered")])])])]) : _c('div', {
    staticClass: "steps d-flex flex-column justify-content-between mt-3"
  }, [_c('div', {
    class: "step d-flex mb-5 ".concat(_vm.orderStatus(_vm.orderDetailsData.orderStatus) >= 1 ? 'completed' : '')
  }, [_c('div', {
    staticClass: "round"
  }), _c('div', {
    staticClass: "ml-4"
  }, [_c('h6', [_vm._v("Order Created")])])]), _c('div', {
    class: "step d-flex mb-5 ".concat(_vm.orderStatus(_vm.orderDetailsData.orderStatus) >= 4 ? 'cancelled' : '')
  }, [_c('div', {
    staticClass: "round"
  }), _c('div', {
    staticClass: "ml-4"
  }, [_c('h6', [_vm._v("Order Cancelled")])])])])])]), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('h5', [_vm._v("Delivery & Billing Info")]), _c('div', {
    staticClass: "delivery-billing-div"
  }, [_c('div', {
    staticClass: "delivery-div"
  }, [_c('h5', [_vm._v("Delivery Address")]), _c('p', [_vm._v(" " + _vm._s(_vm.orderDetailsData && _vm.orderDetailsData.deliveryAddress && _vm.orderDetailsData.deliveryAddress.address ? _vm.orderDetailsData.deliveryAddress.address.fullName : "") + " ")]), _c('p', [_vm._v(" " + _vm._s(_vm.orderDetailsData && _vm.orderDetailsData.deliveryAddress && _vm.orderDetailsData.deliveryAddress.address ? _vm.orderDetailsData.deliveryAddress.address.street1 : "") + ", ")]), _c('p', [_vm._v(" " + _vm._s(_vm.orderDetailsData && _vm.orderDetailsData.deliveryAddress && _vm.orderDetailsData.deliveryAddress.address ? _vm.orderDetailsData.deliveryAddress.address.city : "") + ", " + _vm._s(_vm.orderDetailsData && _vm.orderDetailsData.deliveryAddress && _vm.orderDetailsData.deliveryAddress.address ? _vm.orderDetailsData.deliveryAddress.address.state : "") + "- " + _vm._s(_vm.orderDetailsData && _vm.orderDetailsData.deliveryAddress && _vm.orderDetailsData.deliveryAddress.address ? _vm.orderDetailsData.deliveryAddress.address.zip : "") + " ")]), _c('p', [_vm._v("+91 " + _vm._s(_vm.getContactDetails("PRIMARY_PHONE")))])]), _c('div', {
    staticClass: "delivery-div"
  }, [_c('hr'), _c('h5', [_vm._v("Billing Address")]), _c('p', [_vm._v(" " + _vm._s(_vm.orderDetailsData && _vm.orderDetailsData.deliveryAddress && _vm.orderDetailsData.deliveryAddress.address ? _vm.orderDetailsData.deliveryAddress.address.fullName : "") + " ")]), _c('p', [_vm._v(" " + _vm._s(_vm.orderDetailsData && _vm.orderDetailsData.deliveryAddress && _vm.orderDetailsData.deliveryAddress.address ? _vm.orderDetailsData.deliveryAddress.address.street1 : "") + ", ")]), _c('p', [_vm._v(" " + _vm._s(_vm.orderDetailsData && _vm.orderDetailsData.deliveryAddress && _vm.orderDetailsData.deliveryAddress.address ? _vm.orderDetailsData.deliveryAddress.address.city : "") + ", " + _vm._s(_vm.orderDetailsData && _vm.orderDetailsData.deliveryAddress && _vm.orderDetailsData.deliveryAddress.address ? _vm.orderDetailsData.deliveryAddress.address.state : "") + "- " + _vm._s(_vm.orderDetailsData && _vm.orderDetailsData.deliveryAddress && _vm.orderDetailsData.deliveryAddress.address ? _vm.orderDetailsData.deliveryAddress.address.zip : "") + " ")]), _c('p', [_vm._v("+91 " + _vm._s(_vm.getContactDetails("PRIMARY_PHONE")))])])]), _c('div', {
    staticClass: "d-flex justify-content-between mt-3"
  }, [(_vm.orderDetailsData.orderStatus === 'Processing' || _vm.orderDetailsData.orderStatus === 'Dispatched' || _vm.orderDetailsData.orderStatus === 'Delivered' || _vm.orderDetailsData.orderStatus === 'Cancelled' ? true : false) ? _c('button', {
    staticClass: "btn btn-outline-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.orderAgain
    }
  }, [_c('span', {
    staticClass: "pr-3 pl-3"
  }, [_vm._v("Order Again")])]) : _c('button', {
    staticClass: "btn btn-outline-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.cancelOrder(_vm.orderDetailsData.orderId);
      }
    }
  }, [_c('span', {
    staticClass: "pr-3 pl-3"
  }, [_vm._v("Cancel Order")])]), _c('button', {
    staticClass: "btn btn-primary ml-3",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.needHelpClick(_vm.orderDetailsData.orderId);
      }
    }
  }, [_c('span', {
    staticClass: "pr-4 pl-4"
  }, [_vm._v("Need Help")])])]), _c('div', {
    staticClass: "mt-3 d-flex justify-content-center invoice-btn",
    on: {
      "click": _vm.fetchInvoiceData
    }
  }, [_c('button', {
    attrs: {
      "type": "button"
    }
  }, [_c('span', [_vm._v("View Invoice")])])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }