<template>
  <v-container class="mt-0">
    <b-row>
      <b-col md="8" class="pt-0">
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex text-center align-items-center top-div">
            <router-link button to="/my-order">
              <b-icon icon="arrow-left-short"></b-icon>
            </router-link>
            <h5>
              Order
              <span
                >#{{ orderDetailsData ? orderDetailsData.orderId : "" }}</span
              >
            </h5>
            <div class="order-status status-type-div ml-3">
              <p>{{ orderDetailsData ? orderDetailsData.orderStatus : "" }}</p>
            </div>
            <div class="order-type status-type-div ml-3">
              <p>{{ orderDetailsData ? orderDetailsData.type : "" }}</p>
            </div>
          </div>
          <div class="d-flex text-center align-items-center order-date-div">
            <b-icon icon="calendar3"></b-icon>
            <p class="ml-2">{{ createdDate }}</p>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-0">
      <b-col md="8">
        <div
          class="ordered-item-div mb-3"
          v-if="orderDetailsData.modeOfSale !== 'IN-STORE'"
        >
          <h5>Ordered Item</h5>
          <ul
            v-for="(item, index) in orderDetailsData.items"
            :key="index"
            class="list-unstyled mb-0"
          >
            <template v-if="$options.components['items-order-details-item']">
              <items-order-details-item :itemData="item" />
            </template>
          </ul>
        </div>
        <div class="content-div">
          <h5>Seller Details</h5>
          <div class="d-flex justify-content-between seller-name mt-3">
            <div>
              <div class="d-flex align-items-center">
                <router-link
                  :to="{
                    path: `/shop-details/${orderDetailsData.provider._id}/${providerName}`,
                  }"
                >
                  <b-img
                    :src="
                      sellerInfo &&
                      sellerInfo.organization &&
                      sellerInfo.organization.logo
                        ? sellerInfo.organization.logo.url
                        : ''
                    "
                    class="seller-img"
                    alt=""
                  />
                </router-link>
                <router-link
                  :to="{
                    path: `/shop-details/${orderDetailsData.provider._id}/${providerName}`,
                  }"
                >
                  <p class="ml-2">{{ providerName }}</p>
                </router-link>
              </div>
              <div class="d-flex mt-3">
                <b-icon icon="geo-alt-fill" class="ml-1 mr-1"></b-icon>
                <div class="ml-3">
                  <p>
                    {{
                      sellerInfo &&
                      sellerInfo.office &&
                      sellerInfo.office.address
                        ? sellerInfo.office.address.street1
                        : ""
                    }}
                    {{
                      sellerInfo &&
                      sellerInfo.office &&
                      sellerInfo.office.address
                        ? sellerInfo.office.address.city
                        : ""
                    }}
                  </p>
                  <p>
                    {{
                      sellerInfo &&
                      sellerInfo.office &&
                      sellerInfo.office.address
                        ? sellerInfo.office.address.state
                        : ""
                    }}-
                    {{
                      sellerInfo &&
                      sellerInfo.office &&
                      sellerInfo.office.address
                        ? sellerInfo.office.address.zip
                        : ""
                    }}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div class="d-flex align-items-center pt-1">
                <b-icon icon="envelope-fill"></b-icon>
                <p class="ml-2">{{ sellerMail }}</p>
              </div>
              <div class="d-flex align-items-center mt-3 pt-1">
                <b-icon icon="telephone-forward-fill"></b-icon>
                <p class="ml-2">{{ sellerPhone }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3 content-div">
          <div class="d-flex justify-content-between payment-box">
            <div class="d-flex align-items-center">
              <h5>Payment Summary</h5>
              <div
                class="status-type-div payment-status ml-4"
                v-if="orderDetailsData.paymentStatus"
              >
                <p>
                  {{ paymentStatus }}
                </p>
              </div>
              <div class="status-type-div payment-type ml-4">
                <p>
                  {{ orderDetailsData ? orderDetailsData.paymentType : "" }}
                </p>
              </div>
            </div>
            <p
              class="payment-ref"
              v-if="
                orderDetailsData &&
                orderDetailsData.paymentDetails &&
                orderDetailsData.paymentDetails.transactionId
                  ? true
                  : false
              "
            >
              Payment Ref: #{{
                orderDetailsData && orderDetailsData.paymentDetails
                  ? orderDetailsData.paymentDetails.transactionId
                  : ""
              }}
            </p>
          </div>
          <div v-for="item in orderDetailsData.items" :key="item._id">
            <div
              class="d-flex justify-content-between mt-3"
              v-if="orderDetailsData.modeOfSale !== 'IN-STORE'"
            >
              <p>Subtotal</p>
              <p>₹ {{ item.price }}</p>
            </div>
            <div class="d-flex justify-content-between" v-if="item.delivery">
              <p>Shipping Cost</p>
              <p>₹ {{ item.delivery.charge }}</p>
            </div>
            <div v-if="item.taxes.length >= 1">
              <div
                class="d-flex justify-content-between"
                v-for="tax in item.taxes"
                :key="tax.type"
              >
                <p>{{ tax.type }} @ {{ tax.percent }}%</p>
                <p>₹ {{ tax.taxAmount.toFixed(2) }}</p>
              </div>
              <div class="d-flex justify-content-between">
                <p>Total Tax Amount</p>
                <p>₹ {{ item.totalTaxes }}</p>
              </div>
            </div>
          </div>
          <hr class="mt-2" />
          <div class="d-flex justify-content-between total-bill">
            <h5>Total</h5>
            <h5>{{ orderDetailsData ? orderDetailsData.orderAmount : "" }}</h5>
          </div>
        </div>
        <div class="mt-3 content-div">
          <h5>Order Activity</h5>
          <div
            class="steps d-flex flex-column justify-content-between mt-3"
            v-if="orderDetailsData.orderStatus !== 'Cancelled'"
          >
            <div
              :class="`step d-flex mb-5 ${
                orderStatus(orderDetailsData.orderStatus) >= 0
                  ? 'completed'
                  : ''
              }`"
            >
              <div class="round"></div>
              <div class="ml-4">
                <h6>Order Created</h6>
                <!-- <p>Order was created on 11-Nov-2022 10:30:12 AM</p> -->
              </div>
            </div>
            <div
              :class="`step d-flex mb-5 ${
                orderStatus(orderDetailsData.orderStatus) >= 1
                  ? 'completed'
                  : ''
              }`"
            >
              <div class="round"></div>
              <div class="ml-4">
                <h6>Order Processing</h6>
                <!-- <p>Order was created on 11-Nov-2022 10:30:12 AM</p> -->
              </div>
            </div>
            <div
              :class="`step d-flex mb-5 ${
                orderStatus(orderDetailsData.orderStatus) >= 2
                  ? 'completed'
                  : ''
              }`"
            >
              <div class="round"></div>
              <div class="ml-4">
                <h6>Shipped</h6>
                <!-- <p>Order was created on 11-Nov-2022 10:30:12 AM</p> -->
              </div>
            </div>
            <div
              :class="`step d-flex mb-5 ${
                orderStatus(orderDetailsData.orderStatus) >= 3
                  ? 'completed'
                  : ''
              }`"
            >
              <div class="round"></div>
              <div class="ml-4">
                <h6>Order Delivered</h6>
                <!-- <p>Order was created on 11-Nov-2022 10:30:12 AM</p> -->
              </div>
            </div>
          </div>
          <div
            class="steps d-flex flex-column justify-content-between mt-3"
            v-else
          >
            <div
              :class="`step d-flex mb-5 ${
                orderStatus(orderDetailsData.orderStatus) >= 1
                  ? 'completed'
                  : ''
              }`"
            >
              <div class="round"></div>
              <div class="ml-4">
                <h6>Order Created</h6>
                <!-- <p>Order was created on 11-Nov-2022 10:30:12 AM</p> -->
              </div>
            </div>
            <div
              :class="`step d-flex mb-5 ${
                orderStatus(orderDetailsData.orderStatus) >= 4
                  ? 'cancelled'
                  : ''
              }`"
            >
              <div class="round"></div>
              <div class="ml-4">
                <h6>Order Cancelled</h6>
                <!-- <p>Order was created on 11-Nov-2022 10:30:12 AM</p> -->
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col md="4">
        <h5>Delivery & Billing Info</h5>
        <div class="delivery-billing-div">
          <div class="delivery-div">
            <h5>Delivery Address</h5>
            <p>
              {{
                orderDetailsData &&
                orderDetailsData.deliveryAddress &&
                orderDetailsData.deliveryAddress.address
                  ? orderDetailsData.deliveryAddress.address.fullName
                  : ""
              }}
            </p>
            <p>
              {{
                orderDetailsData &&
                orderDetailsData.deliveryAddress &&
                orderDetailsData.deliveryAddress.address
                  ? orderDetailsData.deliveryAddress.address.street1
                  : ""
              }},
            </p>
            <p>
              {{
                orderDetailsData &&
                orderDetailsData.deliveryAddress &&
                orderDetailsData.deliveryAddress.address
                  ? orderDetailsData.deliveryAddress.address.city
                  : ""
              }},
              {{
                orderDetailsData &&
                orderDetailsData.deliveryAddress &&
                orderDetailsData.deliveryAddress.address
                  ? orderDetailsData.deliveryAddress.address.state
                  : ""
              }}-
              {{
                orderDetailsData &&
                orderDetailsData.deliveryAddress &&
                orderDetailsData.deliveryAddress.address
                  ? orderDetailsData.deliveryAddress.address.zip
                  : ""
              }}
            </p>
            <p>+91 {{ getContactDetails("PRIMARY_PHONE") }}</p>
          </div>
          <div class="delivery-div">
            <hr />
            <h5>Billing Address</h5>
            <p>
              {{
                orderDetailsData &&
                orderDetailsData.deliveryAddress &&
                orderDetailsData.deliveryAddress.address
                  ? orderDetailsData.deliveryAddress.address.fullName
                  : ""
              }}
            </p>
            <p>
              {{
                orderDetailsData &&
                orderDetailsData.deliveryAddress &&
                orderDetailsData.deliveryAddress.address
                  ? orderDetailsData.deliveryAddress.address.street1
                  : ""
              }},
            </p>
            <p>
              {{
                orderDetailsData &&
                orderDetailsData.deliveryAddress &&
                orderDetailsData.deliveryAddress.address
                  ? orderDetailsData.deliveryAddress.address.city
                  : ""
              }},
              {{
                orderDetailsData &&
                orderDetailsData.deliveryAddress &&
                orderDetailsData.deliveryAddress.address
                  ? orderDetailsData.deliveryAddress.address.state
                  : ""
              }}-
              {{
                orderDetailsData &&
                orderDetailsData.deliveryAddress &&
                orderDetailsData.deliveryAddress.address
                  ? orderDetailsData.deliveryAddress.address.zip
                  : ""
              }}
            </p>
            <p>+91 {{ getContactDetails("PRIMARY_PHONE") }}</p>
          </div>
          <!-- <div class="delivery-time">
            <hr />
            <h5>Estimated Delivery Time</h5>
            <p>12-Nov-2022 10:23 AM</p>
          </div> -->
        </div>
        <div class="d-flex justify-content-between mt-3">
          <button
            type="button"
            v-if="
              orderDetailsData.orderStatus === 'Processing' ||
              orderDetailsData.orderStatus === 'Dispatched' ||
              orderDetailsData.orderStatus === 'Delivered' ||
              orderDetailsData.orderStatus === 'Cancelled'
                ? true
                : false
            "
            class="btn btn-outline-primary"
            @click="orderAgain"
          >
            <span class="pr-3 pl-3">Order Again</span>
          </button>
          <button
            type="button"
            class="btn btn-outline-primary"
            @click="cancelOrder(orderDetailsData.orderId)"
            v-else
          >
            <span class="pr-3 pl-3">Cancel Order</span>
          </button>
          <button
            type="button"
            class="btn btn-primary ml-3"
            @click="needHelpClick(orderDetailsData.orderId)"
          >
            <span class="pr-4 pl-4">Need Help</span>
          </button>
        </div>
        <div
          class="mt-3 d-flex justify-content-center invoice-btn"
          @click="fetchInvoiceData"
        >
          <button type="button" class="">
            <span>View Invoice</span>
          </button>
        </div>
      </b-col>
    </b-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  computed: {
    ...mapState({
      orderDetailsData: (state) => state.profile.orderDetailsData,
      cancelled: (state) => state.profile.cancelled,
      cartData: (state) => state.checkout.cartData,
      sellerInfo: (state) => state.profile.sellerInfo,
    }),
    createdDate() {
      return moment(this.orderDetailsData.createdAt).format(
        "MMMM Do YYYY, hh:mm a"
      );
    },
    providerName() {
      const name = Array.from(
        new Set(this.orderDetailsData.items?.map((each) => each.providerName))
      );
      return name[0];
    },
    paymentStatus() {
      const payment = Array.from(
        new Set(
          this.orderDetailsData.payments?.items?.map((each) => each.status)
        )
      );
      return payment[0];
    },
    sellerMail() {
      const mail = Array.from(
        new Set(this.sellerInfo.office?.email.map((each) => each.mailId))
      );
      return mail[0];
    },
    sellerPhone() {
      const number = Array.from(
        new Set(this.sellerInfo.office?.phone.map((each) => each.number))
      );
      return number[0];
    },
  },
  methods: {
    getContactDetails(e) {
      try {
        if (this.orderDetailsData.deliveryAddress.address.contacts) {
          for (let element of this.orderDetailsData.deliveryAddress.address
            .contacts) {
            if (element.contactType == e) {
              return element.value;
            }
          }

          return "";
        } else {
          return "";
        }
      } catch {
        return "";
      }
    },
    orderStatus(e) {
      switch (e) {
        case "Confirmed":
          return 0;
        case "Processing":
          return 1;
        case "Dispatched":
          return 2;
        case "Delivered":
          return 3;
        case "Cancelled":
          return 4;

        default:
          return -1;
      }
    },
    async orderAgain() {
      const result = this.orderDetailsData.items.map((each) => {
        return {
          _id: each.productId,
          imageLink: each.imageLink,
          description_summary: each.name,
          qty: each.qty,
          Price: each.price,
          providerName: each.providerName,
        };
      });
      try {
        result.map((each) => {
          if (this.cartData.length > 0) {
            if (
              this.cartData[0].providerName ==
              this.orderDetailsData.items[0].providerName
            ) {
              const list = [];
              list.push(each);
              list.map((each) => {
                this.$store.dispatch("checkout/increaseCartQty", {
                  cartItem: each,
                  callApi: this.callCheckout,
                });
                this.loading = false;
                if (this.callCheckout)
                  this.$store.dispatch("checkout/checkoutFinal");
              });

              this.$swal({
                title: "Added To Cart",
                text: "Please proceed with your order.",
                imageUrl: "img/cart-popup.jpg",
                imageHeight: 80,
                imageWidth: 80,
                timer: 1100,
              });

              setTimeout(() => {
                this.$router.push("/checkout/");
                this.$router.push("/checkout/");
              }, 1500);
            } else {
              this.$swal({
                title: `Sure??`,
                text: "You have product from different seller in your cart.Do you want to remove it and add this item?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes",
              }).then(async (res) => {
                if (res.value) {
                  await this.$store.dispatch("checkout/clearCart");
                  result.map((each) => {
                    this.$store.dispatch("checkout/increaseCartQty", {
                      cartItem: each,
                      callApi: this.callCheckout,
                    });
                    if (this.callCheckout)
                      this.$store.dispatch("checkout/checkoutFinal");
                  });

                  this.$swal({
                    title: "Added To Cart",
                    text: "Please proceed with your order.",
                    imageUrl: "img/cart-popup.jpg",
                    imageHeight: 80,
                    imageWidth: 80,
                    timer: 1100,
                  });

                  setTimeout(() => {
                    this.$router.push("/checkout/");
                  }, 1500);
                }
                this.loading = false;
              });
            }
          } else {
            this.$store.dispatch("checkout/increaseCartQty", {
              cartItem: each,
              callApi: this.callCheckout,
            });
            this.loading = false;
            if (this.callCheckout)
              this.$store.dispatch("checkout/checkoutFinal");
            this.$swal({
              title: "Added To Cart",
              text: "Please proceed with your order.",
              imageUrl: "img/cart-popup.jpg",
              imageHeight: 80,
              imageWidth: 80,
              timer: 1100,
            });
            setTimeout(() => {
              this.$router.push("/checkout/");
            }, 1500);
          }
        });
      } catch {
        this.loading = false;
      }
      await this.$store.dispatch("checkout/fetchCartData", {
        shipFrom: "IN",
        shipTo: "IN",
        country: "IN",
      });
    },
    async cancelOrder(orderId) {
      this.$swal({
        title: "Are you sure?",
        text: "You want to Cancel the Order!",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn-danger",
        confirmButtonText: "Yes, Cancel Order!",
        cancelButtonText: "No don't Cancel!",
        closeOnConfirm: false,
        closeOnCancel: false,
      }).then(async (result) => {
        if (result.value) {
          this.$swal({
            timer: 1100,
            title: "Cancelled!",
            text: "Your Order has been Cancelled.",
          });
          this.$store.dispatch("profile/fetchCancelOrderData", orderId);
        }
      });
    },
    needHelpClick(orderId) {
      this.$store.dispatch("profile/getOrderDetailsData", orderId);
      this.$router.push({
        name: "need-help-orderId",
        params: { orderId: orderId },
      });
    },
    fetchInvoiceData() {
      this.$store.dispatch(
        "profile/getInvoiceAction",
        this.orderDetailsData.orderId
      );

      this.$router.push("/order-invoice");
    },
  },
  async mounted() {
    await this.$store.dispatch(
      "profile/fetchSellerDetails",
      this.orderDetailsData.provider?._id
    );
  },
};
</script>

<style scoped>
.bi-arrow-left-short {
  height: 3rem;
  width: 3rem;
  padding: 0px;
}
.top-div h5 {
  margin-bottom: 0px;
}
.top-div span {
  color: #3a38c5;
}
a {
  color: #272727;
  /* text-decoration: none; */
  cursor: pointer;
  text-decoration: none;
}
.status-type-div {
  padding: 4px 10px;
  border-radius: 14px;
}
.order-status {
  border: 1px solid #40ad5d;
  color: #40ad5d;
  background-color: #d5ebdb;
}
.payment-status {
  border: 1px solid #504ee3;
  color: #504ee3;
  background-color: #d3d2ff;
}
.status-type-div p {
  margin-bottom: 0px;
  font-size: 12px;
}
.order-type {
  border: 1px solid #ed7710;
  color: #ed7710;
  background-color: #ffd6b2;
}
.payment-type {
  border: 1px solid #d052b6;
  color: #d052b6;
  background-color: #f5d5ee;
}

.order-date-div p {
  margin-bottom: 0px;
}
.order-date-div {
  color: #949494;
}
.item-qty span {
  color: #aaaaaa;
}
.ordered-item-div {
  border: 1px solid #eff1f5;
  padding: 14px 20px;
  border-radius: 12px;
}
.content-div {
  border: 1px solid #eff1f5;
  padding: 14px 20px;
  border-radius: 12px;
}
.payment-box h5 {
  margin-bottom: 0px;
}
.payment-ref {
  margin-bottom: 0px;
  color: #949494;
}
.total-bill {
  color: #3a38c5;
}
.total-bill h5 {
  margin-bottom: 0px;
}
.delivery-billing-div {
  border: 1px solid #eff1f5;
  padding: 14px 24px;
  border-radius: 12px;
}
.delivery-billing-div p {
  margin-bottom: 3px;
  font-weight: 500;
}
.delivery-billing-div hr {
  margin: 10px 0px;
}
.delivery-time p {
  color: #fd7e14;
  font-weight: 500;
  margin-bottom: 0px !important;
}
.invoice-btn {
  background-color: #ff9900;
  border: 1px solid #ff9900;
  color: #fff;
  padding: 7px 0px;
  border-radius: 0.25rem;
  cursor: pointer;
}
.seller-name p {
  margin-bottom: 0px;
}
.seller-img {
  width: 32px;
  height: 32px;
  border-radius: 16px;
}
.bi-geo-alt-fill {
  margin-top: 4px;
}
.round {
  height: 30px;
  width: 30px;
  border-radius: 15px;

  background-color: #949494;
}

.steps .step::before {
  display: block;
  position: absolute;
  height: 6%;
  left: 47px;
  margin-top: -49px;
  border: 1px dashed #949494;
  content: "";
  z-index: -2;
}
.steps .step::after {
  display: block;
  position: absolute;
  height: 6%;
  left: 47px;
  margin-top: 34px;
  border: 1px dashed #949494;
  content: "";
  z-index: -1;
}

.steps .step:first-child::before {
  display: none;
}

.steps .step:last-child::after {
  display: none;
}

.steps .step.completed::before,
.steps .step.completed::after {
  border-color: #04c800;
}

.steps .step.completed .round {
  background-color: #04c800;
}
.steps .step.cancelled .round {
  background-color: red;
}
</style>
